import { motion } from "framer-motion";
import React from "react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import "./App.css";

const profileImg = 'https://avatars.githubusercontent.com/u/3128408'

const App: React.FC = () => {
  return (
    <div className="app">
      <main>
        <div className="profile">
          <motion.img
            whileHover={{ scale: 1.2, rotate: 360 }}
            whileTap={{ scale: 0.8, rotate: -360, borderRadius: "100%" }}
            src={profileImg}
            alt="Alex Tavella"
            title="Alex Tavella"
          />
          <h1>@alextavella</h1>
        </div>
        <div className="networking">
          <a
            href="https://www.linkedin.com/in/alextavella"
            target="blank"
            title="LinkedIn"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://github.com/alextavella"
            target="blank"
            title="Github"
          >
            <FaGithub />
          </a>
          <a
            href="https://twitter.com/alextavella"
            target="blank"
            title="Twitter"
          >
            <FaTwitter />
          </a>
        </div>
      </main>
    </div>
  );
};

export default App;
